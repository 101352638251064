/* Add this to your CSS file */
@import url('https://fonts.googleapis.com/css2?family=Cinzel+Decorative:wght@400;700;900&display=swap');


.banner {
    background-color: #12151B;
    text-align: center;
    padding: 400px 0px;;

}

.banner-content {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 80px; /* Adds top margin to the whole content */
    margin-bottom: 80px;
}

.banner-logo {
    width: 80%; /* Adjust size to fit the design */
    max-width: 300px;
    height: auto;
    margin: 0 20px; /* Adds spacing between the logo and the text */
}

.banner-text {
    font-family: "Cinzel Decorative", serif;
    font-weight: 700;
    font-size: 4rem;
    color: #DBA555;
    margin: 0 15px; /* Adds spacing between text and logo */
}

/* Media Queries */
@media (max-width: 768px) {
    .banner{
        order: -1;
        padding: 40px 0px;
        width: 100%;

    }

    .banner-text {
        font-size: 1rem; /* Further reduce for smaller screens */
        display: none;
    }

    .banner-logo{
        width: 125px;     
    } 
}