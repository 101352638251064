/* ContactUs.css */

/* Import the fonts */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cinzel+Decorative:wght@400;700;900&display=swap');

.contact-us-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  background-color: #12151B; /* Dark background color */
  color: #DBA555; /* Gold color */
  font-family: 'Montserrat', sans-serif;

}

.contact-section{
  max-width: 800px;
  text-align: center;
  margin: 2rem 0;
  background-color: transparent; /* Remove background color */
  color: #DBA555; /* Gold color */
  padding: 0; /* Remove padding */
  border: none; /* Remove borders */
  box-shadow: none; /* Remove shadows */
}

.contact-us-logo-image {
  margin-top: 200px;
  margin-bottom: 200px;
  width: 300px;
}

.contact-us-contact-section {
  max-width: 800px;
  text-align: center;
  margin: 2rem 0;
  background-color: transparent; /* Remove background color */
  color: #DBA555; /* Gold color */
  padding: 0; /* Remove padding */
  border: none; /* Remove borders */
  box-shadow: none; /* Remove shadows */
}

.contact-us-section-title {
  font-family: 'Cinzel Decorative', serif;
  font-weight: 700;
  font-size: 2.5rem;
  color: #DBA555; /* Gold color */
  margin-bottom: 120px;
  font-style: none;
}

.contact-us-section-description {
  font-size: 1.5rem;
  line-height: 1.6;
  text-align: center;
  color: #DBA555; /* Gold color */
  font-family: 'Montserrat', sans-serif;
  margin-bottom: 100px;
}


/* Form styles remain unchanged */
.contact-form {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.form-label {
  font-weight: bold;
  margin-bottom: 10px;
  color: #DBA555;
}

.form-input,
.form-textarea {
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #DBA555;
  border-radius: 5px;
  font-size: 1rem;
  font-family: 'Montserrat', sans-serif;
  background-color: transparent; /* Keep it transparent to blend with the background */
  color: #DBA555; /* Gold color for text */
}

.form-textarea {
  resize: vertical; /* Allow vertical resizing */
  height: 150px; /* Set a default height for the textarea */
}

.form-button {
  padding: 10px;
  background-color: #DBA555; /* Gold background color */
  color: #12151B; /* Dark text color */
  border: none;
  border-radius: 5px;
  font-size: 1.2rem;
  cursor: pointer;
  transition: background-color 0.3s;
}

.form-button:hover {
  background-color: #c49a45; /* Darker gold on hover */
}

@media (max-width: 768px) {


  .contact-us-logo-image{
    width: 125px;     
    margin-top: 200px;
    margin-bottom: 50px;
   } 
 
   .contact-us-section-title{
     font-size: 1.5rem;
     margin-bottom: 20px;
   }
 
   .contact-us-section-description {
     font-size: 1rem;
   }
 

}