/* AboutUs.css */

/* Import the fonts */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cinzel+Decorative:wght@400;700;900&display=swap');

.about-us-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  background-color: #12151B; /* Dark background color */
  color: #DBA555; /* Gold color */
  font-family: 'Montserrat', sans-serif;
}

.about-us-logo {
  margin-top: 200px;
  margin-bottom: 200px;
  width: 300px;
  height: auto;
}


.about-section,
.logo-section {
  max-width: 800px;
  text-align: center;
  margin: 2rem 0;
  background-color: transparent; /* Remove background color */
  color: #DBA555; /* Gold color */
  padding: 0; /* Remove padding */
  border: none; /* Remove borders */
  box-shadow: none; /* Remove shadows */
}

.about-us-section-title {
  font-family: 'Cinzel Decorative', serif;
  font-weight: 700;
  font-size: 2.5rem;
  color: #DBA555; /* Gold color */
  margin-bottom: 120px;
  font-style: none;
}

.about-us-section-description {
  font-size: 1.5rem;
  line-height: 1.6;
  margin-bottom: 100px;
  color: #DBA555; /* Gold color */
  font-family: 'Montserrat', sans-serif;
}

/* Media Queries */
@media (max-width: 768px) {

  .about-us-logo{
    width: 125px;     
   margin-top: 200px;
   margin-bottom: 50px;
  } 

  .about-us-section-title{
    font-size: 1.5rem;
    margin-bottom: 20px;
  }

  .about-us-section-description {
    font-size: 1rem;
  }

}
