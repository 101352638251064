/* Add this to your CSS file */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cinzel+Decorative:wght@400;700;900&display=swap');

/* General reset to make sizing consistent */
*,
*::before,
*::after {
    box-sizing: border-box;
}

.services-section {
    width: 100%;
    background-color: #12151B;
    color: #DBA555;
    padding: 40px 40px;
    text-align: center;
}

.services-title {
    
    font-family: "Cinzel Decorative", serif;
    font-weight: 500;
    font-size: 2.5rem;
    margin-bottom: 60px;
    color: #DBA555;
}

.services-grid {
    width: 100%;
    padding: 40px 0px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    justify-items: stretch; /* Ensure all cards are stretched to fit */
    align-items: stretch; /* Ensure all cards are stretched to fit */
}

.service-card {
    background-color: #12151B;
    padding: 20px; /* Adjust padding to a uniform size */
    color: #DBA555;
    width:100%;
    text-align: center;
    transition: transform 0.3s, box-shadow 0.3s;
    border-radius: 0px;
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
    font-weight: 500;
    font-style: normal;
    font-size: 1.5rem;
    height: 100%; /* Ensures all cards are of equal height */
}

.service-card p {
    font-size: 1.5rem;
    text-align: center;
}


.service-icon {
    font-size: 6rem;
    color: #DBA555;
    margin-top: 10px;
    margin-bottom: 10px;
}

.service-card:hover {
    transform: translateY(-10px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.5);
}

/* Media Queries */
@media (max-width: 768px) {

    .services-section {
        width: 100%;

        padding: 40px 0px;
    }
    .services-title {
        font-size: 1.8rem; /* Further reduce for smaller screens */
        justify-content: center;
        text-align: center;
        width: 100%;

    }

    .services-grid {
        width: 100%;
        text-wrap: wrap;
        justify-content: center;
        text-align: center;
        grid-template-columns: 1fr;
    }

    .service-card{
        width: 100%;

        font-size: 1rem;
    }

    .service-icon{
        font-size: 2rem;
    }

    .service-card p {
        font-size: 1rem;
        text-align: center;
    }
    
    .service-card h2 {
        font-size: 1.1rem;
        text-align: center;
    }

}