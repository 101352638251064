/* Gallery.css */

/* Import the fonts */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cinzel+Decorative:wght@400;700;900&display=swap');

.gallery-container {
  background-color: #12151B; /* Dark background color */
  color: #DBA555; /* Gold color */
  font-family: 'Montserrat', sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
}

.gallery-section {
  max-width: 100%;
  text-align: center;
  margin: 2rem 0;
  text-align: center;
}


.gallery-logo-image {
  margin-top: 200px;
  margin-bottom: 200px;
  width: 300px;
}


.gallery-title {
  font-family: 'Cinzel Decorative', serif;
  font-weight: 700;
  font-size: 3rem;
  color: #DBA555; /* Gold color */
  margin-bottom: 20px;
}

.gallery-description {
  font-size: 1.5rem;
  margin-bottom: 200px;
  color: #DBA555;                                                                         
}


.section-heading {
  font-family: 'Cinzel Decorative', serif;
  font-size: 2.5rem;
  font-weight: 700;
  color: #DBA555; /* Gold color */
  margin-bottom: 100px;
  text-align: center;
}

.media-grid {
  display: grid;
  gap: 3rem; /* Uniform gap between items */
  justify-items: center; /* Centers items within their grid cells */
  align-items: center; /* Center items vertically */
  width: 100%; /* Ensures the grid spans the entire container */
  margin-bottom: 100px;
}

/* For one or two items in the grid, center them */
.media-grid[data-item-count="1"] {
  grid-template-columns: 1fr; /* Single column */
}

.media-grid[data-item-count="2"] {
  grid-template-columns: repeat(2, 1fr); /* Two columns */
}

.media-grid[data-item-count="3"] {
  grid-template-columns: repeat(3, 1fr); /* Three columns */
}


/* Styles for logo items (square boxes) */
.media-item-logo {
  background-color: #1E222B; /* Slightly lighter dark background */
  border: 2px solid #DBA555; /* Gold border */
  border-radius: 8px;
  padding: 1rem;
  color: #DBA555; /* Gold color */
  font-size: 1.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 250px; /* Square width */
  height: 250px; /* Square height */
  overflow: hidden;
  transition: transform 0.3s, box-shadow 0.3s;
}

/* Styles for game and webpage items (landscape rectangles) */
.media-item-game, .media-item-webpage {
  background-color: #1E222B; /* Slightly lighter dark background */
  border: 2px solid #DBA555; /* Gold border */
  border-radius: 8px;
  padding: 1rem;
  color: #DBA555; /* Gold color */
  font-size: 1.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 480px; /* Adjusted width for landscape rectangle */
  height: 270px; /* Height to maintain 16:9 ratio */
  overflow: hidden;
  transition: transform 0.3s, box-shadow 0.3s;
}


/* Flip card styles for logos */
.flip-card-logo, .flip-card-game, .flip-card-webpage {
  width: 250px;
  height: 250px;
  perspective: 1000px;
}

.flip-card-inner-logo {
  position: relative;
  width: 100%;
  height: 100%;
  transition: transform 0.6s;
  transform-style: preserve-3d;
}

.flip-card-logo.flipped .flip-card-inner-logo {
  transform: rotateY(180deg);
}

.flip-card-front-logo,
.flip-card-back-logo {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  backface-visibility: hidden;
  border: 2px solid #DBA555;
  border-radius: 8px;
}

.flip-card-back-logo {
  background-color: #1B1F26;
  transform: rotateY(180deg);
}

/* Flip card styles for games */
.flip-card-game {
  width: 480px; /* Adjusted width for landscape rectangle */
  height: 270px; /* Height to maintain 16:9 ratio */
  perspective: 1000px;
}

.flip-card-inner-game {
  position: relative;
  width: 100%;
  height: 100%;
  transition: transform 0.6s;
  transform-style: preserve-3d;
}

.flip-card-game.flipped .flip-card-inner-game {
  transform: rotateY(180deg);
}

.flip-card-front-game,
.flip-card-back-game {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  backface-visibility: hidden;
  border: 2px solid #DBA555;
  border-radius: 8px;
}

.flip-card-back-game {
  background-color: #1B1F26;
  transform: rotateY(180deg);
}

/* Flip card styles for webpages */
.flip-card-webpage {
  width: 480px; /* Adjusted width for landscape rectangle */
  height: 270px; /* Height to maintain 16:9 ratio */
  perspective: 1000px;
}

.flip-card-inner-webpage {
  position: relative;
  width: 100%;
  height: 100%;
  transition: transform 0.6s;
  transform-style: preserve-3d;
}

.flip-card-webpage.flipped .flip-card-inner-webpage {
  transform: rotateY(180deg);
  opacity: 100%;
}

.flip-card-front-webpage,
.flip-card-back-webpage {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  backface-visibility: hidden;
  border: 2px solid #DBA555;
  border-radius: 8px;
}

.flip-card-back-webpage {
  background-color: #1B1F26;
  transform: rotateY(180deg);
}

/* Ensure consistent hover effects */
.flip-card-logo:hover,
.flip-card-game:hover,
.flip-card-webpage:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.5);
}

@media (max-width: 768px) {
  
  .gallery-logo-image{
    width: 125px;     
    margin-top: 200px;
    margin-bottom: 50px;
   } 
 
   .gallery-title{
     font-size: 1.5rem;
     margin-bottom: 10px;
   }
 
   .gallery-section-description {
     font-size: 1rem;
   }
 

.flip-card-game{
  width: 300px; /* Adjusted width for landscape rectangle */
  height: 182.5px;
}

.flip-card-webpage{
  width: 300px; /* Adjusted width for landscape rectangle */
  height: 182.5px;
}

.media-item-game{
  width: 300px; /* Adjusted width for landscape rectangle */
  height: 182.5px;
}

.media-item-webpage{
  width: 300px; /* Adjusted width for landscape rectangle */
  height: 182.5px;
}

.media-grid{
  gap: 3rem;
  grid-template-columns: 1fr !important;
  width: 100%;
}

.gallery-description {
  font-size: 1rem;
  margin-bottom: 100px;
}

  .section-heading {
    font-size: 1.5rem;
  }
  
}